import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const HomeSolutions = () => {
    return (
        <section data-scrollax-parent="true" id="Highlights">
            <div className="section-subtitle" data-scrollax="properties: { translateY: '-250px' }" ><span>//</span>highlights </div>
            <div className="container">
                <div className="section-title fl-wrap">
                    <h3>Highlights</h3>
                    <h2>Why <span> Automation</span></h2>
          
                </div>
                <div className="process-wrap fl-wrap">
                    <ul>
                        <li>
                            <div className="time-line-icon">
                                <i className="fab pragicts-efficiency-icon"></i>
                            </div>
                            <h4>Increased Efficiency and Productivity</h4>
                            <div className="process-details">
                                {/* <h6>Duis autem vel eum iriure dolor</h6> */}
                                <p>Automation streamlines processes, reducing the time required for tasks. It operates consistently without fatigue or breaks, leading to higher productivity levels. Repetitive tasks can be completed swiftly, allowing human workers to focus on more complex and creative endeavors.</p>
                                {/* <a href="#">More Details</a> */}
                            </div>
                            <span className="process-numder">01.</span>
                        </li>
                        <li>
                            <div className="time-line-icon">
                                <i className="fal pragicts-reduction-icon"></i>
                            </div>
                            <h4>Cost Reduction</h4>
                            <div className="process-details">
                                {/* <h6>In ut odio libero, at vulputate urna. </h6> */}
                                <p>By automating routine tasks, businesses can significantly cut down on operational costs. Automated systems can work 24/7 without additional pay, reducing labor expenses over time. Moreover, the decreased likelihood of errors minimizes expenses related to rework or corrections.</p>
                                {/* <a href="#">More Details</a> */}
                            </div>
                            <span className="process-numder">02.</span>
                        </li>
                        <li>
                            <div className="time-line-icon">
                                <i className="fal pragicts-quality-icon"></i>
                            </div>
                            <h4>Enhanced Accuracy and Quality</h4>
                            <div className="process-details">
                                {/* <h6>Nulla posuere sapien vitae lectus suscipit</h6> */}
                                <p>Automation reduces human error, leading to more precise and consistent outcomes. This is particularly crucial in industries like manufacturing and healthcare, where precision is paramount. Automated systems adhere strictly to predefined parameters, ensuring a higher quality of work.</p>
                                {/* <a href="#">More Details</a> */}
                            </div>
                            <span className="process-numder">03.</span>
                        </li>
                        <li>
                            <div className="time-line-icon">
                                <i className="fal pragicts-safety-icon"></i>
                            </div>
                            <h4>Improved Safety</h4>
                            <div className="process-details">
                                {/* <h6>Nulla posuere sapien vitae lectus suscipit</h6> */}
                                <p>In industries involving hazardous environments or risky tasks, automation plays a pivotal role in ensuring worker safety. Machines can handle dangerous jobs, mitigating the risk of workplace accidents and injuries.</p>
                                {/* <a href="#">More Details</a> */}
                            </div>
                            <span className="process-numder">04.</span>
                        </li>
                        <li>
                            <div className="time-line-icon">
                                <i className="fab pragicts-faster-icon"></i>
                            </div>
                            <h4>Faster Decision Making</h4>
                            <div className="process-details">
                                {/* <h6>Duis autem vel eum iriure dolor</h6> */}
                                <p>Automation often includes data processing and analysis tools that can swiftly generate insights from vast amounts of information. This allows for faster decision-making, enabling businesses to adapt to changing market conditions more effectively.</p>
                                {/* <a href="#">More Details</a> */}
                            </div>
                            <span className="process-numder">05.</span>
                        </li>
                        <li>
                            <div className="time-line-icon">
                                <i className="fal pragicts-scalability-icon"></i>
                            </div>
                            <h4>Scalability</h4>
                            <div className="process-details">
                                {/* <h6>In ut odio libero, at vulputate urna. </h6> */}
                                <p>Automated systems can easily scale up or down according to demand without significant reconfiguration. This scalability is particularly beneficial in industries experiencing fluctuating workloads.</p>
                                {/* <a href="#">More Details</a> */}
                            </div>
                            <span className="process-numder">06.</span>
                        </li>
                        <li>
                            <div className="time-line-icon">
                                <i className="fal pragicts-experience-icon"></i>
                            </div>
                            <h4>Enhanced Customer Experience</h4>
                            <div className="process-details">
                                {/* <h6>Nulla posuere sapien vitae lectus suscipit</h6> */}
                                <p>Automation can streamline customer service processes, providing quicker response times, personalized interactions, and consistent service quality. Chatbots and automated systems can handle routine queries, freeing up human agents to focus on more complex customer needs.
</p>
                                {/* <a href="#">More Details</a> */}
                            </div>
                            <span className="process-numder">07.</span>
                        </li>
                        <li>
                            <div className="time-line-icon">
                                <i className="fal pragicts-innovation-icon"></i>
                            </div>
                            <h4>Innovaytion and Creativity</h4>
                            <div className="process-details">
                                {/* <h6>Nulla posuere sapien vitae lectus suscipit</h6> */}
                                <p>By taking over repetitive tasks, automation frees up human workers to focus on innovation and creativity. This fosters a culture of innovation within organizations, leading to the development of new products, services, and solutions.</p>
                                {/* <a href="#">More Details</a> */}
                            </div>
                            <span className="process-numder">08.</span>
                        </li>
                        <li>
                            <div className="time-line-icon">
                                <i className="fal pragicts-environmental-icon"></i>
                            </div>
                            <h4>Environmental Impact</h4>
                            <div className="process-details">
                                {/* <h6>Nulla posuere sapien vitae lectus suscipit</h6> */}
                                <p>Automated systems can be designed to optimize energy consumption and reduce waste, contributing to a more sustainable environment. By optimizing processes, businesses can decrease their carbon footprint.</p>
                                {/* <a href="#">More Details</a> */}
                            </div>
                            <span className="process-numder">09.</span>
                        </li>
                        <li>
                            <div className="time-line-icon">
                                <i className="fal pragicts-creation-icon"></i>
                            </div>
                            <h4>Job Creation and Evolution</h4>
                            <div className="process-details">
                                {/* <h6>Nulla posuere sapien vitae lectus suscipit</h6> */}
                                <p>While automation may replace certain jobs, it also creates new opportunities. It leads to the emergence of roles centered around managing, maintaining, and improving automated systems. Additionally, it often allows humans to focus on jobs that require emotional intelligence, critical thinking, and complex problem-solving—areas where machines currently struggle.</p>
                                {/* <a href="#">More Details</a> */}
                            </div>
                            <span className="process-numder">10.</span>
                        </li>
                    </ul>
                </div>
            </div>
           
            <div className="sec-lines"></div>
        </section>
    )
}

export default HomeSolutions
