import React from 'react'

import { Link } from "gatsby"


import automation from '../images/services/auto/pragicts-automation-services-network-automation.jpg'
import monitoring from '../images/services/auto/pragicts-automation-services-monitoring.jpg'
import backup from '../images/services/auto/pragicts-automation-services-backup-recovery.jpg'
import virtualization from '../images/services/auto/pragicts-automation-services-virtualization.jpg'
import workflow from '../images/services/auto/pragicts-automation-services-workflow-automation.jpg'
import scripting from '../images/services/auto/pragicts-automation-services-scripting-coding.jpg'
import configuration from '../images/services/auto/pragicts-automation-services-configuration-management.jpg'
import patch from '../images/services/auto/pragicts-automation-services-patch-management.jpg'
import chatbots from '../images/services/auto/pragicts-automation-services-it-services-management.jpg'
import identity from '../images/services/auto/pragicts-automation-services-identity-management.jpg'

const HomeStats = () => {

    return (
        <section className="dark-bg no-padding" id="services">
             <div className="section-subtitle" data-scrollax="properties: { translateY: '-250px' }" ><span>//</span>Services </div>
            <div >
                <div className="section-title fl-wrap">
                    <h3>Our Services</h3>
                    <h2>Services</h2>
          
                </div>
        <div className="fs-carousel-wrap fl-wrap full-height slider-carousel-wrap">
            <div className="fs-carousel fl-wrap full-height cur_carousel-slider-container">
                <div style={{ paddingTop: '10px', paddingBottom: '10px' }} className="fs-carousel-item">
                    <div className="fs-carousel-title fl-wrap">

                        <h3><Link to="/services">Network Automation</Link></h3>
                        <Link to="/services" className="fs-carousel-link">More Details</Link>
                    </div>
                    <div className="carousle-item-number"><span>01.</span></div>
                    <div className="bg"  data-bg={automation}></div>
                    <div className="overlay"></div>
                </div>
                <div style={{ paddingTop: '10px', paddingBottom: '10px' }} className="fs-carousel-item">
                    <div className="fs-carousel-title fl-wrap">

                        <h3><a target="_blank" href="/services">Monitoring and Alerting Automation</a></h3>
                        <a target="_blank" href="/services" className="fs-carousel-link">More Details</a>
                    </div>
                    <div className="carousle-item-number"><span>02.</span></div>
                    <div className="bg"  data-bg={monitoring}></div>
                    <div className="overlay"></div>
                </div>
                <div style={{ paddingTop: '10px', paddingBottom: '10px' }} className="fs-carousel-item">
                    <div className="fs-carousel-title fl-wrap">

                        <h3><a target="_blank" href="/services">Backup and Recovery Automation</a></h3>
                        <a target="_blank" href="/services" className="fs-carousel-link">More Details</a>
                    </div>
                    <div className="carousle-item-number"><span>03.</span></div>
                    <div className="bg"  data-bg={backup}></div>
                    <div className="overlay"></div>
                </div>
                <div style={{ paddingTop: '10px', paddingBottom: '10px' }} className="fs-carousel-item">
                    <div className="fs-carousel-title fl-wrap">

                        <h3><a target="_blank" href="/services">Virtualization and Cloud Automation</a></h3>
                        <a target="_blank" href="/services" className="fs-carousel-link">More Details</a>
                    </div>
                    <div className="carousle-item-number"><span>04.</span></div>
                    <div className="bg"  data-bg={virtualization}></div>
                    <div className="overlay"></div>
                </div>
                <div style={{ paddingTop: '10px', paddingBottom: '10px' }} className="fs-carousel-item">
                    <div className="fs-carousel-title fl-wrap">

                        <h3><a target="_blank" href="/services">Workflow Automation</a></h3>
                        <a target="_blank" href="/services" className="fs-carousel-link">More Details</a>
                    </div>
                    <div className="carousle-item-number"><span>05.</span></div>
                    <div className="bg"  data-bg={workflow}></div>
                    <div className="overlay"></div>
                </div>
                <div style={{ paddingTop: '10px', paddingBottom: '10px' }} className="fs-carousel-item">
                    <div className="fs-carousel-title fl-wrap">

                        <h3><a target="_blank" href="/services">Scripting and Coding</a></h3>
                        <a target="_blank" href="/services" className="fs-carousel-link">More Details</a>
                    </div>
                    <div className="carousle-item-number"><span>06.</span></div>
                    <div className="bg"  data-bg={scripting}></div>
                    <div className="overlay"></div>
                </div>
                <div style={{ paddingTop: '10px', paddingBottom: '10px' }} className="fs-carousel-item">
                    <div className="fs-carousel-title fl-wrap">

                        <h3><a target="_blank" href="/services">Configuration Management</a></h3>
                        <a target="_blank" href="/services" className="fs-carousel-link">More Details</a>
                    </div>
                    <div className="carousle-item-number"><span>07.</span></div>
                    <div className="bg"  data-bg={configuration}></div>
                    <div className="overlay"></div>
                </div>
                <div style={{ paddingTop: '10px', paddingBottom: '10px' }} className="fs-carousel-item">
                    <div className="fs-carousel-title fl-wrap">

                        <h3><a target="_blank" href="/services">Patch Management</a></h3>
                        <a target="_blank" href="/services" className="fs-carousel-link">More Details</a>
                    </div>
                    <div className="carousle-item-number"><span>08.</span></div>
                    <div className="bg"  data-bg={patch}></div>
                    <div className="overlay"></div>
                </div>   <div style={{ paddingTop: '10px', paddingBottom: '10px' }} className="fs-carousel-item">
                    <div className="fs-carousel-title fl-wrap">

                        <h3><a target="_blank" href="/services">Identity and Access Management (IAM)</a></h3>
                        <a target="_blank" href="/services" className="fs-carousel-link">More Details</a>
                    </div>
                    <div className="carousle-item-number"><span>09.</span></div>
                    <div className="bg"  data-bg={identity}></div>
                    <div className="overlay"></div>
                </div>
                <div style={{ paddingTop: '10px', paddingBottom: '10px' }} className="fs-carousel-item">
                    <div className="fs-carousel-title fl-wrap">

                        <h3><a target="_blank" href="/services">Chatbots and IT Service Management (ITSM)</a></h3>
                        <a target="_blank" href="/services" className="fs-carousel-link">More Details</a>
                    </div>
                    <div className="carousle-item-number"><span>10.</span></div>
                    <div className="bg"  data-bg={chatbots}></div>
                    <div className="overlay"></div>
                </div>
         
               
            </div>
            <div className="sp-cont sarr-contr sp-cont-prev"><i className="fal fa-arrow-left"></i></div>
            <div className="sp-cont sarr-contr sp-cont-next"><i className="fal fa-arrow-right"></i></div>
            <div className="slider-nav-counter"></div>
        </div>
        </div>
    </section>

    )
}

export default HomeStats
