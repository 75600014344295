import React from 'react'
import puppet from '../images/technologies/puppet.png'
import chef from '../images/technologies/chef.png'
import saltstack from '../images/technologies/saltstack.png'
import jenkins from '../images/technologies/jenkins.png'
import airflow from '../images/technologies/airflow.png'
import kubernetes from '../images/technologies/kubernetes.png'
import powershell from '../images/technologies/powershell.png'
import bash from '../images/technologies/bash.png'
import prometheus from '../images/technologies/tech-prometheus-a7dcee35e78dc1d81752ef09b419760c.png'
import nagios from '../images/technologies/nagios.png'
import zabbix from '../images/technologies/zabbix.png'
import gitlab from '../images/technologies/gitlab.png'

import travis_ci from '../images/technologies/travis_ci.png'
import servicenow from '../images/technologies/servicenow.png'
import ansible_tower from '../images/technologies/ansible_tower.png'
import ansible from '../images/technologies/ansible.png'
import python from '../images/clients/python.png'







const clients = [
    {
        name: 'puppet',
        logo: puppet,
        url: 'https://www.puppet.com/'
    },
    {
        name: 'chef',
        logo: chef,
        url: 'https://www.chef.io/'
    },
    {
        name: 'ansible',
        logo: ansible,
        url: 'https://www.ansible.com/'
    },
    {
        name: 'saltstack',
        logo: saltstack,
        url: 'https://saltproject.io/index.html'
    },
    {
        name: 'jenkins',
        logo: jenkins,
        url: 'https://www.jenkins.io/'
    },
    {
        name: 'airflow',
        logo: airflow,
        url: 'https://airflow.apache.org/'
    },
    {
        name: 'kubernetes',
        logo: kubernetes,
        url: 'https://kubernetes.io/'
    },
    {
        name: 'python',
        logo: python,
        url: 'https://www.python.org/'
    },
    {
        name: 'powershell',
        logo: powershell,
        url: ''
    },
    {
        name: 'bash',
        logo: bash,
        url: ''
    },
    {
        name: 'nagios',
        logo: nagios,
        url: 'https://www.nagios.com/'
    },
    {
        name: 'zabbix',
        logo: zabbix,
        url: 'https://www.zabbix.com/index'
    },
    {
        name: 'prometheus',
        logo: prometheus,
        url: 'https://prometheus.io/'
    },
    {
        name: 'gitlab',
        logo: gitlab,
        url: 'https://docs.gitlab.com/ee/ci/'
    },
    {
        name: 'travis_ci',
        logo: travis_ci,
        url: 'https://www.travis-ci.com/'
    },
    {
        name: 'servicenow',
        logo: servicenow,
        url: 'https://www.servicenow.com/'
    },
    {
        name: 'ansible_tower',
        logo: ansible_tower,
        url: '#'
    }
]

const Technologies = () => {
    return (
        <section  data-scrollax-parent="true" id="technologies">
            <div className="section-subtitle"  data-scrollax="properties: { translateY: '-250px' }" >Technologies<span>//</span></div>
            <div className="container" >
                <div className="section-title fl-wrap">
                
                    <h2>Technology <span>Stack</span></h2>
                 
                </div>
            </div>
        
         
            <div className="fl-wrap">
                <div className="container">
                    <ul className="client-list client-list-white">
                        {clients.map(client => (
                            <li key={client.name}><a href={client.url} target="_blank"><img src={client.logo} className='respimg' alt={client.name} title={client.name} /></a></li>
                        ))}
                    </ul>
                </div>
            </div>
            <div className="sec-lines"></div>
        </section>
    )
}

export default Technologies
